<template>
  <div ref="wrapper"
    class="video-item-wrapper"
    :class="{'hover': showOverlay, 'small': size === 'small'}"
    :style="autoHeight"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click="goDetail"
  >
    <div class="video-item-image">
      <img :src="coverImg" />
    </div>
    <div class="video-bottom-image">
      <img src="@/assets/images/video-bottom.png" />
    </div>
    <div class="video-item-overlay">
      <div class="overlay-wrapper">
        <div class="overlay-top-bar"></div>
        <div class="overlay-middle-bar"></div>
        <div class="overlay-bottom-bar">
          <div class="video-duration"> {{ duration }} </div>
          <div class="video-title">
            <div class="video-title__content" :style="'max-width: ' + titleMaxWidth + ';'">
              <el-tooltip effect="light" placement="bottom-start" :content="title"> <span>{{ showTitle }}</span> </el-tooltip>
              <!-- {{ showTitle }} -->
            </div>
            <div class="bottom-actions">
              <div class="trigger-btn">
                <el-popover
                  v-if="showAction && haveAnyAction"
                  placement="top-end"
                  trigger="hover"
                  :visible-arrow="false"
                  popper-class="actions-popover"
                >
                  <template #reference>
                    <div class="trigger-reference">
                      <img src="@/assets/images/trigger.png" />
                      <span>操作</span>
                    </div>
                  </template>
                  <template #default>
                    <div class="trigger-actions">
                      <div class="video-actions">
                        <div v-if="showAddTopIcon" class="video-action" @click.stop="handleAddTop">
                          <el-tooltip class="item" effect="dark" :open-delay="500" content="置顶" placement="top-start">
                            <img src="@/assets/images/zhiding.png" />
                          </el-tooltip>
                        </div>
                        <div v-if="showRemoveTopIcon" class="video-action" @click.stop="handleRemoveTop">
                          <el-tooltip class="item" effect="dark" :open-delay="500" content="取消置顶" placement="top-start">
                            <img src="@/assets/images/quxiao.png" />
                          </el-tooltip>
                        </div>
                        <div v-if="showEditIcon" class="video-action" @click.stop="goEdit">
                          <el-tooltip class="item" effect="dark" :open-delay="500" content="编辑" placement="top-start">
                            <img src="@/assets/images/edit.png" />
                          </el-tooltip>
                        </div>
                        <div v-if="showDeleteIcon" class="video-action" @click.stop="del">
                          <el-tooltip class="item" effect="dark" :open-delay="500" content="删除" placement="top-start">
                            <img src="@/assets/images/delete.png" />
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </template>
                </el-popover>
              </div>
              <div class="video-click">
                <i class="el-icon-view"> {{ clickNum }} </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="unpublished" class="unpublished-overlay">
      <div class="unpublished-wrapper">
        <div class="unpublished-content"> 视频审核中，请等待发布 </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoItem',
  props: {
    video: {
      type: Object,
      required: true
    },
    // 是否是显示在置顶列表中
    top: {
      type: Boolean,
      default: false
    },
    // 是否显示操作按钮
    showAction: {
      type: Boolean,
      default: true
    },
    // 可选 size 选项: normal, small
    size: {
      type: String,
      default: 'normal'
    },
    // 视频列表页的查询参数
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      // 权限
      perms: JSON.parse(window.sessionStorage.getItem('perms')),
      showOverlay: false,
      // 标题最长字符数
      // titleMaxLength: 25,
      titleMaxLength: 21,
      autoHeight: {
        height: ''
      },
      titleMaxWidth: '',
    }
  },
  computed: {
    title() {
      let text = ''
      if (this.video && this.video.title) text = this.video.title
      return text
    },
    // 展示用的标题
    showTitle() {
      let title = ''
      if (this.video) {
        title = this.video.title
      }
      // 如果超长，进行截取并添加 '...'
      if (title.length > this.titleMaxLength) {
        title = title.substring(0, this.titleMaxLength)
        title += '...'
      }
      return title
    },
    // 视频时长
    duration() {
      let duration = ''
      if (this.video && this.video.videoDuration) duration = this.video.videoDuration
      return duration
    },
    // 视频封面
    coverImg() {
      let url = ''
      if (this.video) {
        url = this.video.videoImg
      }
      return url
    },
    // 点击量
    clickNum() {
      let num = 0
      if (this.video && this.video.clickNum) num = this.video.clickNum
      return num
    },
    // 是否为超级管理员
    // 是 才能 (删除或编辑各种状态下的通信箱)
    isSuperAdmin() {
      const userInfoStr = window.sessionStorage.getItem('userInfo')
      if (!userInfoStr) return false

      const userInfo = JSON.parse(userInfoStr)
      if (!userInfo) return false
      if (userInfo.superAdminFlag === '1') return true

      return false
    },
    // 是否是管理员
    // 是 才能 (删除或编辑各种状态下的通信箱)
    isAdmin() {
      const userInfoStr = window.sessionStorage.getItem('userInfo')
      if (!userInfoStr) return false

      const userInfo = JSON.parse(userInfoStr)
      if (!userInfo) return false
      if (userInfo.adminFlag === '1') return true

      return false
    },
    // 是否显示置顶按钮
    showAddTopIcon() {
      if (this.video && this.video.topFlag == 0 && this.perms.includes('video:addTop')) return true
      return false
    },
    // 是否显示取消置顶按钮
    showRemoveTopIcon() {
      if (this.video && this.video.topFlag == 1 && this.top && this.perms.includes('video:removeTop')) return true
      return false
    },
    // 是否显示编辑按钮
    showEditIcon() {
      if (this.isSuperAdmin || this.isAdmin || (this.perms.includes('video:update') && this.video.publishFlag === 0)) return true
      return false
    },
    // 是否显示删除按钮
    showDeleteIcon() {
      if (this.perms.includes('video:delete')) return true
      return false
    },
    // 是否有可用的操作
    haveAnyAction() {
      if (this.showAddTopIcon || this.showRemoveTopIcon || this.showEditIcon || this.showDeleteIcon) return true
      return false
    },
    // 未发布
    unpublished() {
      if (this.video && this.video.publishFlag == 0) return true
      return false
    },
  },
  created() {
    window.addEventListener('resize', this.recalcSize)
  },
  mounted() {
    this.recalcSize()
  },
  destroyed() {
    window.removeEventListener('resize', this.recalcSize)
  },
  methods: {
    onMouseEnter() {
      this.showOverlay = true
    },
    onMouseLeave() {
      this.showOverlay = false
    },
    // 置顶
    handleAddTop() {
      this.$emit('onAddTop')
    },
    // 取消置顶
    handleRemoveTop() {
      this.$emit('onRemoveTop')
    },
    // 删除
    handleDelete() {
      this.$emit('onDelete')
    },
    // 计算高度
    recalcSize() {
      // console.log('监听事件触发')
      if (this.$refs.wrapper) {
        // console.log('wrapper 存在')
        const width = this.$refs.wrapper.offsetWidth
        const ratio = 169 / 260
        const height =  width * ratio

        this.autoHeight.height = height + 'px'

        if (this.size === 'small') {
          this.titleMaxWidth = (width - 70) + 'px'
        } else {
          this.titleMaxWidth = (width - 120) + 'px'
        }
      }
    },
    // 跳转视频详情页
    goDetail() {
      console.log('跳转视频详情页')
      if (!this.perms.includes('video:detail')) {
        this.$message.error('你所在的代理商没有此权限或者你的区域代理没有此权限，通过通信箱向渠道支援咨询');
        return
      }

      const page = {
        name: 'videoDetail',
        query: {
          id: this.video.id
        },
        params: this.params
      }

      if (this.$route.name === 'videoDetail') {
        this.$router.replace(page)
      } else {
        this.$router.push(page)
      }
    },
    // 跳转编辑页面
    goEdit() {
      console.log('跳转视频编辑页')
      this.$router.push({
        name: 'videoAddEdit',
        query: {
          id: this.video.id
        },
        params: this.params
      })
      this.$emit('onEdit')
    },
    del() {
      this.$emit('onDelete')
    },
  }
}
</script>

<style lang="scss" scoped>
.video-item-wrapper {
  // width: 200px;
  // height: 130px;
  width: 260px;
  height: 169px;
  position: relative;
  border: 1px solid #DDDDDD;
  overflow: hidden;
  user-select: none;

  &.small {
    .video-title__content {
      font-size: 12px !important;
    }
    .unpublished-overlay {
      &::before {
          font-size: 1.5em !important;
      }
      .unpublished-wrapper {
        font-size: 10px;
      }
    }
  }
  &.hover {
    .video-item-image {
      z-index: 10;

      img {
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
  }
  .video-item-image, .video-item-overlay, .unpublished-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .video-item-image {
    z-index: 10;

    img {
      width: 100%;
      height: 100%;

      transform: scale(1);
      transition: 0.3s;
    }
  }
  .video-bottom-image {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    // height: 40px;
    height: 70px;
    z-index: 11;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .video-item-overlay {
    z-index: 20;
    display: flex;
    align-items: stretch;

    .overlay-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;

      .overlay-top-bar {}
      .overlay-middle-bar {
        flex-grow: 1;
      }
      .overlay-bottom-bar {
        color: white;

        .video-duration {
          // font-size: 12px;
          font-size: 14px;
        }
        .video-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .video-title__content {
            max-width: 170px;
            // font-size: 14px;
            font-size: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .bottom-actions {
            display: flex;
            align-items: center;

            .trigger-btn {
              margin-right: 10px;

              .trigger-reference {
                display: flex;
                align-items: center;
                cursor: pointer;

                span {
                  font-size: 14px;
                }
              }

              img {
                width: 12px;
                height: 12px;
              }
              span {
                text-indent: 4px;
                font-size: 12px;
              }
            }
            .video-click {
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }
      }
      
    }
  }
  .unpublished-overlay {
    z-index: 10;
    // background-color: rgba(0, 0, 0, .1);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(255, 255, 255, 0));
    overflow: auto;
    box-sizing: content-box;

    &::before {
      color: #fff;
      content: 'X';
      font-family: Arial, Helvetica, sans-serif;
      font-size: 2em;
      left: 0;
      line-height: 1;
      margin-top: -0.5em;
      position: absolute;
      text-shadow: 0.05em 0.05em 0.1em #000;
      text-align: center;
      top: 50%;
      vertical-align: middle;
      width: 100%;
    }
    .unpublished-wrapper {
      width: 100%;
      height: 100%;
      z-index: 10;
      color: white;
      // font-size: 12px;
      font-size: 14px;
      text-align: center;

      .unpublished-content {
        padding: 10px;
      }
    }
  }
}
// .video-actions {
//   display: flex;
// }
// /deep/ .el-popover {
//   padding: 0 !important;
// }
/deep/ .actions-popover {
  background: transparent;

  .trigger-actions {
    .video-actions {
      display: flex;
    }
  }
}
</style>

<style lang="scss">
.actions-popover {
  padding: 0;
  min-width: unset;
  background-color: transparent;
  border: none;
  box-shadow: unset;
  // margin-top: 20px !important;
  padding-top: 10px;

  .trigger-actions {
    position: relative;

    .video-actions {
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .video-action {
        display: flex;
        align-items: center;
        margin-right: 2px;

        &:last-of-type {
          margin: 0;
        }
        img {
          width: 26px;
          max-width: 26px;
          min-width: 26px;
          height: 26px;
          max-height: 26px;
          min-height: 26px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
